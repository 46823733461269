import { User } from '@consigli/types';
import { FC } from 'react';
import { FaEyeSlash } from 'react-icons/fa';

interface AdminBannerProps {
  user: User;
  published: boolean;
  backgroundColor?: string;
}

export const AdminBanner: FC<AdminBannerProps> = ({ user, published, backgroundColor = '' }) => {
  if (!user.isSuperuser) {
    return null;
  }
  if (!published) {
    return (
      <div className={`flex justify-end w-full ${backgroundColor}`}>
        <div className="mr-4 mt-2 flex">
          <p className="mr-2 text-day-neutral-dark text-opacity-40 text-base">Not public</p>
          <div className="text-day-neutral-dark text-opacity-40">
            <FaEyeSlash size={24} />
          </div>
        </div>
      </div>
    );
  }
};
